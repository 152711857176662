import 'persian-datepicker/dist/js/persian-datepicker.min'
import moment      from 'jalali-moment'
import {Sortable}  from '@shopify/draggable';
import Num2persian from 'num2persian';
import Storage     from './custom/Storage';

window.Sortable    = Sortable;
window.Num2persian = Num2persian;
window.Storage     = new Storage();
window.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

import "./helpers"
import "./custom/leaflet/leaflet"
import "./custom/clipboard/clipboard"
import "./components/date-picker"
import "./components/number-dialer"
import "./components/tinymce"
import "./components/tagify"
import "./components/loading"
import "./components/scroll"
import "./components/draggable"
import "./components/select2"
import "./components/table"
import "./components/image-preview"
import "./components/otp-auth"
import "./components/countdown"
import "./components/uppy-drag-drop"

window.moment   = moment;
window.momentjs = (date) => {
    return moment(date).locale('fa')
};
toastr.options  = {
    "closeButton"      : true,
    "debug"            : false,
    "newestOnTop"      : false,
    "progressBar"      : false,
    "positionClass"    : "toastr-bottom-right",
    "preventDuplicates": false,
    "onclick"          : null,
    "showDuration"     : "300",
    "hideDuration"     : "1000",
    "timeOut"          : "5000",
    "extendedTimeOut"  : "1000",
    "showEasing"       : "swing",
    "hideEasing"       : "linear",
    "showMethod"       : "fadeIn",
    "hideMethod"       : "fadeOut",
    "rtl"              : true
};

function toast(event) {
    let {type, message} = event;
    toastr[type](message);
}

['livewire:init'].forEach((event) => {
    document.addEventListener(event, function () {
        Livewire.on('toast', function (event) {
            toast(event[0]);
        });
    });
})
