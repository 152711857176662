document.addEventListener('alpine:init', () => {
    Alpine.data('otpAuth', () => {
        return {
            code  : '',
            inputs: null,
            init() {
                Livewire.hook('morph.updated',  (el, component) => {
                    this.createOtp();
                    this.getValue();
                    this.inputs[this.code.length ? this.code.length - 1 : 0].focus();
                });
                this.createOtp()
            },
            createOtp() {
                this.inputs = document.querySelectorAll('.get-verify-code input');
                if(this.inputs.length > 0) {
                    this.inputs[0].focus();
                    this.inputs.forEach((input, index) => {

                        input.dataset.index = index;
                        input.addEventListener("keyup", (e) => {
                            this.handleOtp(e);
                        });
                        input.addEventListener("paste", (e) => {
                            this.handleOnPasteOtp(e)
                        });
                    });
                }

            },
            handleOtp(e) {
                /**
                 * <input type="text" 👉 maxlength="1" />
                 * 👉 NOTE: On mobile devices `maxlength` property isn't supported,
                 * So we to write our own logic to make it work. 🙂
                 */
                const input      = e.target;
                let value        = input.value;
                let isValidInput = value.match(/\d/g);
                input.value      = "";
                input.value      = isValidInput ? value[0] : "";

                let fieldIndex = parseInt(input.dataset.index);
                if (fieldIndex < this.inputs.length - 1 && isValidInput) {
                    input.nextElementSibling.focus();
                }

                if (e.key === "Backspace" && fieldIndex > 0) {
                    input.previousElementSibling.focus();
                }

                if (fieldIndex === this.inputs.length - 1 && isValidInput) {
                    this.getValueAndSubmit();
                }
            },
            handleOnPasteOtp(e) {
                const data  = e.clipboardData.getData("text");
                const value = data.split("");
                if (value.length === this.inputs.length) {
                    this.inputs.forEach((input, index) => (input.value = value[index]));
                    this.getValueAndSubmit();

                    this.inputs[4].focus()
                }
            },

            getValue() {
                let otp = "";
                this.inputs.forEach((input) => {
                    otp += input.value;
                    //input.disabled = true;
                    //input.classList.add("disabled");
                });

                this.code = otp;
            },

            getValueAndSubmit() {
                this.getValue();
                document.getElementById('otp-submit').click();
            }
        }
    });
});
