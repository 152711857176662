import {
    AwsS3 as UppyAwsS3,
    Uppy as UppyCore,
    StatusBar as UppyStatusBar,
    DragDrop as UppyDragDrop,
}            from "uppy";
import axios from "axios";

class Storage {

    uploadedFiles = {};
    uploadedLinks = [];
    attachedFiles = {};

    init(target, statusTarget, dirPathPrefix, callback) {

        this.UppyCore = new UppyCore({
            debug       : false,
            autoProceed : true,
            restrictions: {
                maxFileSize: 4096000000
            }
        });

        this.UppyCore.use(UppyDragDrop, {
            target          : target,
            maxNumberOfFiles: 10,
            note        : 'فایل های مجاز : عکس، ویدئو، zip، pdf',
            locale          : {
                strings: {
                    dropHereOr  : 'فایلها رو بکش اینجا رها کن! یا %{browse}',
                    browse      : 'کلیک کن!'
                }
            },
        });

        this.UppyCore.use(UppyStatusBar, {
            target          : statusTarget,
            hideAfterFinish : true,
            locale          : {
                strings: {
                    complete    : 'تکمیل شده',
                    uploading   : 'در حال بارگذاری',
                    uploadFailed: 'بارگذاری با خطا مواجه شد.',
                    paused      : 'متوقف شده',
                    retry       : 'تلاش دوباره',
                    cancel      : 'لغو',
                    pause       : 'توقف',
                    resume      : 'از سرگیری بارگذاری',
                    done        : 'تمام',
                }
            },
        });

        this.UppyCore.use(UppyAwsS3, {
            getUploadParameters(file) {
                return axios.post('/user/storage/upload-link', {
                    filename   : file.name,
                    filepath   : dirPathPrefix,
                    contentType: file.type,
                }).then((response) => {
                    return response;
                }).then(({data}) => {
                    callback({
                        url: data.link,
                        key: data.key
                    });
                    return {
                        method : data.method,
                        url    : data.url,
                        fields : data.fields,
                        headers: {
                            'Content-Type': file.type,
                        },
                    };
                });
            },
            retryDelays               : [0, 1000, 3000, 5000],
            removeFingerprintOnSuccess: true,
            chunkSize                 : 1024
        });

        return this;
    }

    upload(file) {

        let hasFile = this.UppyCore.getFiles().filter((oldFile) => {
            return file.name === oldFile.name
        });

        if (hasFile.length > 0) {
            return this;
        }

        let addedFileID = this.UppyCore.addFile({data: file, name: file.name});
        this.attachFile(file, addedFileID);
        return this;
    }

    attachFile(file, key) {
        this.attachedFiles[key] = file;
        return this;
    }

    getUploadedFiles(callback) {
        this.UppyCore.on('complete', ({successful}) => {
            successful.forEach((uploadedFile) => {
                this.uploadedFiles[uploadedFile.id] = this.attachedFiles[uploadedFile.id];
                this.uploadedLinks.push({
                    id : uploadedFile.id,
                    key: '',
                    url: uploadedFile.uploadURL
                });
            });

            var map = new Map();
            for (let uploadedLink of this.uploadedLinks) {
                map.set(uploadedLink["url"], uploadedLink);
            }
            var iteratorValues = map.values();
            this.uploadedLinks = [...iteratorValues];
            this.uploadedLinks.reverse();
            callback(this.uploadedFiles, this.uploadedLinks);

        });
    }

    removeFile(key, callback) {
        delete this.uploadedFiles[key];
        this.UppyCore.removeFile(key);

        this.uploadedLinks.filter((item) => {
            return key === item.id
        }).forEach((item, key) => {
            this.uploadedLinks.splice(key, 1);
        });

        callback(this.uploadedFiles, this.uploadedLinks)
    }

    removeAllFiles(callback) {
        this.UppyCore.reset();
        this.uploadedFiles = {};
        this.uploadedLinks = [];
        this.attachedFiles = {};
        callback();
    }
}

export default Storage;
