document.addEventListener('alpine:init', function () {
    Alpine.data('table', function () {
        return {
            menu: null,
            init() {
                this.menu = KTMenu.createInstances();
                this.$watch('menu');
            },

        }
    });
});
