document.addEventListener('alpine:init', function () {
    Alpine.data('datepicker', function (model, format) {
        return {
            currentDate: null,
            newDate    : model,
            picker     : null,
            init() {

                this.picker = this.createInstance();

                /*Livewire.hook('message.processed', (message, component) => {
                    this.createInstance();
                })*/

                this.currentDate = model.initialValue !== null ? (new persianDate((new Date(model.initialValue)).getTime()).format(format)).toEnglishDigits() : null;


                this.$watch('newDate', () => {
                    if (this.newDate !== null) {
                        this.picker?.setDate(this.picker.getState().selected.unixDate);
                    }
                });

            },

            createInstance() {
                return $(this.$refs.datepicker).persianDatepicker({
                    persianDigit: false,
                    initialValue: false,
                    autoClose   : true,
                    timePicker  : {
                        enable: true
                    },
                    format      : format,
                    toolbox     : {
                        enabled: false
                    },
                    onSelect    : () => {
                        this.setValue();
                    }
                });
            },

            reset() {
                this.currentDate = null;
                this.newDate     = null;
                $(this.$refs.datepicker).val('')
            },
            setValue() {
                this.currentDate = (new persianDate(this.picker.getState().selected.unixDate).format(format)).toEnglishDigits();

                this.newDate = window.moment(this.picker.getState().selected.unixDate).format(format);
            }
        }
    })
});
