document.addEventListener('alpine:init', function () {
    Alpine.data('draggable', function (target, model) {
        return {
            drag     : null,
            swappable: null,
            model    : model,
            init() {
                this.swappable = new Sortable(target, {
                    draggable: ".draggable",
                    handle   : ".draggable .draggable-handle",
                    mirror   : {
                        //appendTo: selector,
                        appendTo           : "body",
                        constrainDimensions: true
                    }
                });

                this.swappable.on('sortable:stop', (e) => {
                    //console.log(e.oldIndex, e.newIndex)
                    let array = [];
                    $(e.newContainer).find('.draggable').map(function (key, item) {
                        if (!$(item).hasClass('draggable--original')) {
                            array.push(parseInt($(item).attr(':key')));
                        }
                    });

                    this.model = array;

                });

            },
        }
    });
});
