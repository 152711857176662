document.addEventListener('alpine:init', function () {
    Alpine.data('tagify', function (model) {

        return {
            model : model,
            tags  : '',
            tagify: null,
            init() {
                $(window).on('load', () => {
                    this.tagify = new Tagify(this.$refs.tagify);
                    this.tagify.addTags(this.model);

                    this.tagify.on('add',() => {
                        this.getTags()
                    });

                    this.tagify.on('remove',() => {
                        this.getTags()
                    })
                });
            },

            getTags() {
                this.model = this.tagify.getCleanValue().map((item) => {
                    return item.value
                });
            }
        }
    })
});
