document.addEventListener('alpine:init', function () {
    Alpine.data('tinymce', function (model) {
        return {
            value: model,
            init() {
                tinymce.init({
                    target  : this.$refs.tinymce,
                    themes  : 'modern',
                    height  : 400,
                    menubar : false,
                    language: 'fa_IR',
                    toolbar : [
                        "styleselect fontselect fontsizeselect",
                        "undo redo | cut copy paste | bold italic | link image | alignleft aligncenter alignright alignjustify | ltr rtl",
                        "bullist numlist | outdent indent | blockquote subscript superscript | advlist | autolink | lists charmap | print preview |  code"
                    ],
                    plugins : "advlist autolink link image lists charmap print preview code directionality",
                    setup   : (editor) => {
                        editor.on('blur', (e) => {
                            this.value = editor.getContent();
                        })

                        editor.on('init', (e) => {
                            if (model != null) {
                                editor.setContent(this.value)
                            }
                        })

                        function putCursorToEnd() {
                            editor.selection.select(editor.getBody(), true);
                            editor.selection.collapse(false);
                        }

                        this.$watch('value', (newValue) => {
                            if (newValue !== editor.getContent()) {
                                editor.resetContent(newValue || '');
                                putCursorToEnd();
                            }
                        });
                    }
                })
            }
        }
    });
});
