document.addEventListener('alpine:init', () => {
    Alpine.data('uppyDragDrop', (target, statusBar) => {
        return {
            storage         : null,
            uppyFiles       : {},
            uploadedFiles   : [],
            uploadedLinks   : [],
            copiedLink      : null,
            copyResult      : false,
            oldUploadedLinks: [],
            init() {

                this.oldUploadedFiles();

                this.storage = window.Storage.init(target, statusBar, '', (file) => {
                    this.uppyFiles[file.url] = file.key
                });

                this.storage.getUploadedFiles((files, links) => {
                    let temp           = files;
                    this.uploadedFiles = {};
                    this.uploadedFiles = temp;
                    this.uploadedLinks = links;
                    this.uploadedLinks.forEach((link) => {
                        link.key = this.uppyFiles[link.url];
                    })

                    this.createCookie('RTL_uploader_links', JSON.stringify(this.uploadedLinks), 30)
                    this.$dispatch('uploaded-links', this.uploadedLinks);
                });

            },

            copy(link) {
                navigator.clipboard.writeText(link);
                this.copyResult = true;
                this.copiedLink = link;

                setTimeout(() => {
                    this.copyResult = false
                    this.copiedLink = null
                }, 1000)
            },

            oldUploadedFiles() {
                this.oldUploadedLinks = JSON.parse(this.readCookie('RTL_uploader_links'))
            },

            createCookie(name, value, days) {
                let expires = '';
                if (days) {
                    let date = new Date();
                    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                    expires = "; expires=" + date.toGMTString()
                }

                document.cookie = name + "=" + value + expires + "; path=/";
            },

            readCookie(name) {
                let ca     = document.cookie.split(';');
                let nameEQ = name + "=";
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
                    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
                }
                return null
            },
        }
    });
});
