document.addEventListener('alpine:init', function () {
    Alpine.data('numberDialer', function (model, options) {

        return {
            model        : model,
            currentNumber: model ?? options.min ?? 0,
            dialer       : null,
            init() {
                this.dialer = new KTDialer(this.$refs.NumberDialerParent, JSON.parse(options));
                this.dialer.setValue(parseInt(this.currentNumber));

                this.$watch('currentNumber', () => {
                    this.dialer.setValue(parseInt(this.currentNumber));
                    this.model = this.dialer.value;
                });
            },

            increase() {
                this.currentNumber = this.dialer.increase().value;
            },

            decrease() {
                this.currentNumber = this.dialer.decrease().value;
            },
        }
    })
});
