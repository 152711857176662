document.addEventListener('alpine:init', function () {
    Alpine.data('select2', (model, searchable, disableEventName = '', dispatchName = '') => {
        return {
            model   : model,
            select2 : null,
            selected: null,
            multiple: false,
            init() {
                this.initSelect();

                Livewire.hook('morph.updated', (el, component) => {
                    this.createInstance();
                })
            },

            initSelect() {
                if (this.multiple) {
                    this.selected = [];
                }
                this.createInstance();

                this.select2.on("select2:select", (event) => {
                    if (this.multiple) {
                        this.selected.push(parseInt(event.params.data.id));
                    } else {
                        this.selected = event.params.data.id;
                    }
                });

                this.select2.on("select2:unselect", (event) => {
                    if (this.multiple) {
                        this.selected = this.selected.filter(item => item !== parseInt(event.params.data.id));
                    } else {
                        this.selected = null;
                    }
                });

                this.$watch("selected", (value) => {
                    this.select2.val(value).trigger("change");

                    if (dispatchName !== '') {
                        this.$dispatch(dispatchName, this.selected)
                    }
                    this.model = this.selected;

                });
            },

            createInstance() {
                this.select2 = $(this.$refs.selectInput).select2({
                    minimumResultsForSearch: searchable === 'searchable' ? 0 : 10000000
                });

                /*this.selected = this.model;*/

                if (this.model !== null) {
                    if (this.multiple) {
                        this.selected = this.model instanceof Array ? this.model : [this.model];
                    } else {
                        this.selected = this.model
                    }
                }

                this.select2.val(this.selected).trigger("change");
            },
        }
    });
});
