document.addEventListener('alpine:init', function () {
    Alpine.data('imagePreview', function () {
        return {
            imageUrl: null,
            showPreview: false,
            file: null,
            init() {
                this.$watch('imageUrl', () => {
                    if (this.imageUrl !== null) {
                        this.showPreview = true;
                        return;
                    }
                    this.showPreview = false;
                });


                Livewire.on('image.uploaded', (message, component) => {
                    this.showPreview = false;
                })
            },

            fileChosen(event) {
                this.fileToDataUrl(event, src => this.imageUrl = src)
            },

            fileToDataUrl(event, callback) {
                if (! event.target.files.length) return

                let file = event.target.files[0],
                    reader = new FileReader()

                reader.readAsDataURL(file)
                reader.onload = e => callback(e.target.result)
            },
        }
    })
});
