document.addEventListener('alpine:init', function () {
    Alpine.data('loadingOverlay', (target) => {
        return {
            blockUI: null,
            loadingStarted: false,
            init() {

                Livewire.hook('commit',  () => {
                    this.loadingStarted = false;
                });
                this.blockUI = new KTBlockUI(target);
            },
            loadingShow() {
                if (this.loadingStarted) {
                    return;
                }
                this.blockUI.block();
                this.loadingStarted = true;
            }
        }
    });
});
