document.addEventListener('alpine:init', function () {
    Alpine.data('scroll', (target) => {
        return {
            scroll: null,
            init() {
                this.scroll = new KTScroll(target);
                this.scroll.update();
            },
        }
    });
});
