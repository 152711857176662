// jquery helpers

(function ($) {
    $.fn.hideModal = function () {
        let myModal = bootstrap.Modal.getOrCreateInstance($(this))
        myModal.hide();
    };

    $.fn.showModal = function () {
        let myModal = bootstrap.Modal.getOrCreateInstance($(this))
        myModal.show();
    };

    document.addEventListener('livewire:update', function () {
        $(document).find('[data-bs-toggle="tooltip"]').tooltip();
        $('.dropdown-menu').find('form').click(function (e) {
            e.stopPropagation();
        });

        $(document).find('.dropdown, .dropup, .dropstart, .dropend').find('.dropform').click(function (e) {
            e.stopPropagation();
        });
    });

    document.addEventListener('livewire:load', function () {
        $('.dropdown-menu').find('form').click(function (e) {
            e.stopPropagation();
        });

        $(document).find('.dropdown, .dropup, .dropstart, .dropend').find('.dropform').click(function (e) {
            e.stopPropagation();
        });
    })

    window.numberFormat = function (value) {

        if (typeof value === 'undefined') {
            return 0;
        }
        value = value.toString();
        value += '';
        let x = value.split('.');
        let x1 = x[0];
        let x2 = x.length > 1 ? '.' + x[1] : '';
        let rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ',' + '$2');
        }
        return x1 + x2;

    }
})(jQuery);

// end jquery helpers

// pure js helpers
String.prototype.toEnglishDigits = function () {
    let persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g];
    let arabicNumbers  = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];
    let str            = this.toString();
    if (typeof str === 'string') {
        for (var i = 0; i < 10; i++) {
            str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
        }
    }

    return str;
}

// end pure js helpers
